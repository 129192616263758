<template>
  <div id="devices-transaction">
    <h1>{{ $t('Devices Transactions') }}</h1>

    <ft-from-to-select :fetch-data="getDevicesTransactions"/>

    <v-card class="my-8" outlined>
      <v-data-table
          :items="devices"
          :headers="headers"
          item-key="item.id"
      >
        <template v-slot:item.purchases="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.legacy="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.cashTopUps="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.lastLegacyUpdate="{ value }">
          {{ formatDateTime(value, $t('No update in selected period')) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import FtFromToSelect from "@/components/UIAccessories/FtFromToSelect.vue";

export default {
  name: 'DevicesTransactions',
  components: { FtFromToSelect },
  data () {
    return {
      devices: [],
    }
  },
  computed: {
    headers () {
      return [
        { value: 'name', text: this.$t('Device name') },
        { value: 'serialNumber', text: this.$t('Serial number') },
        { value: 'purchases', text: this.$t('Purchases with Apollo') },
        { value: 'cashTopUps', text: this.$t('Top ups') },
        { value: 'legacy', text: this.$t('Purchases without Apollo') },
        { value: 'lastLegacyUpdate', text: this.$t('Last update (without Apollo)') },
      ]
    }
  },
  methods: {
    async getDevicesTransactions (params) {
      const response = await this.$http.reports.get('v2/dashboard/devices/stats', {
        params
      })
      this.devices = response.data
    },
  }
}
</script>
