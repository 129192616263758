<template>
<v-container>
  <h1>{{ $t('Virtual key transactions') }}</h1>
  <div class="mt-2 mb-4">
    <div class="text-h6">
      {{userDetails.name}} {{userDetails.surname}} - {{userDetails.email}}
    </div>
    <v-chip outlined color="primary" class="my-4" large>
      {{ $t('Balance') }}: <b class="ml-1">{{formatMoney(vk.balance)}}</b> <small class="ml-3">({{formatDateNoSecs(vk.lastTimeUsed)}})</small>
    </v-chip>
    <div v-if="$store.getters.isAdmin || $store.getters.isAdminLoggedAsClient" class="my-2">
      User id: {{userId}}
    </div>

    <div>
      <span class="font-weight-bold">{{ $t('Actions') }}:</span>
      <v-tooltip top>
        <template v-slot:activator="{on, attrs}">
          <v-btn large icon color="primary"
                 v-bind="attrs"
                 v-on="on"
                 @click="topUpVK()"
                 v-if="isClient || isAdminRole"
          >
            <v-icon>
              fa-plus
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Top up user') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{on, attrs}">
          <v-btn large icon color="primary"
                 v-bind="attrs"
                 v-on="on"
                 @click="reverseTopUp"
                 v-if="isClient || isAdminRole"
          >
            <v-icon>
              fa-minus
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Correct top up (remove credit)') }}</span>
      </v-tooltip>
      <v-tooltip top v-if="isAdmin">
        <template v-slot:activator="{on, attrs}">
          <v-btn large icon color="primary"
                 v-bind="attrs"
                 v-on="on"
                 @click="userChargeModal = userDetails"
          >
            <v-icon>
              fa-cart-shopping
            </v-icon>
          </v-btn>
        </template>
        <span>Create purchase (admin only)</span>
      </v-tooltip>
    </div>
  </div>

  <v-card outlined class="mb-6 mt-2" style="max-width: 40rem" v-if="testCardTopUp.show">
    <v-card-title>
      Test Card Top Up
    </v-card-title>
    <v-card-text>
      <div class="d-flex align-center">
        <v-text-field
            v-model="testCardTopUp.amount"
            style="max-width: 10rem"
            label="Importo in cents"
            type="number"
        />
        <v-btn color="primary" class="mx-5" @click="performCardTopUp">Ricarica</v-btn>
      </div>

      <div v-if="testCardTopUp.showPaymentElement">
        <div id="payment-element">
          <!--Stripe.js injects the Payment Element-->
        </div>

        <v-btn color="primary" outlined class="mt-3" @click="completeStripePayment">Paga</v-btn>
      </div>
    </v-card-text>
  </v-card>

  <v-data-table
      :headers="headers"
      :items="transactions"
      item-key="userId"
      sort-by="name"
      class="elevation-1 text-center mt-3"
  >
    <template v-slot:item.id="{ value }">
      <v-tooltip bottom v-if="value">
        <template v-slot:activator="{ on }">
          {{getLast4Digits(value)}}
          <v-chip small link outlined v-on="on" @click="copyTextToClipboard(value)">Copy ID</v-chip>
        </template>
        <span>
            {{value}}
          </span>
      </v-tooltip>
    </template>

    <template v-slot:item.serialNumber="{ value, item }">
      <v-chip v-if="value" link outlined :to="`/devices/${value}`">{{ value }}</v-chip>
      <span v-if="value && isAdmin">{{item.macAddress}}</span>
    </template>
    <template v-slot:item.amount="{ value, item }">
      {{ formatMoneyWithSign(value, item) }}
      <span v-if="item.purchaseAmount && value !== item.purchaseAmount.full" class="text-caption ml-1">
        ({{formatMoney(item.purchaseAmount.full)}})
      </span>
    </template>
    <template v-slot:item.datetime="{ value }">
      <span v-if="isAdmin">{{ formatDateTime(value) }}</span>
      <span v-else>{{ formatDateNoSecs(value) }}</span>
    </template>
    <template v-slot:item.type="{ value }">
      {{ getTransTypeMsgByCode(value) }}
    </template>

    <template v-slot:item.refunded="{ value, item }">
      <div v-if="value">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              Sì
              <span v-if="isAdmin">: {{ getReasonCode(item.refundReason) }} ({{ item.refundReason }})</span>
            </span>
          </template>
          <span>
            {{ $t('Refunded on') }}:<br>
            <b>{{ formatDateTime(item.refundDatetime) }}</b>
          </span>
        </v-tooltip>
      </div>
      <div v-else-if="item.refundedTransactionId">
        <span v-if="!isAdmin">{{ $t('Refunded') }}</span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            {{getLast4Digits(item.refundedTransactionId)}}
            <v-chip small link outlined v-on="on" @click="copyTextToClipboard(item.refundedTransactionId)">Copy refunded ID</v-chip>
          </template>
          <span>
            {{value}}
          </span>
        </v-tooltip>
      </div>
      <div v-else>-</div>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn v-if="item.type === 'PURCHASE_VK'"
             :disabled="item.refunded || item.disabled"
             small text color="primary"
             @click="refundTransaction(item)"
      >
        {{ $t('Refund') }}
      </v-btn>
    </template>
  </v-data-table>

  <top-up-virtual-key-modal
      :ft-client-id="ftClientId"
      :user.sync="userTopUpModal"
      @close="reloadDelayed"
  />
  <revert-top-up-virtual-key-modal
      :ft-client-id="ftClientId"
      :user.sync="userReverseTopUpModal"
      @close="reloadDelayed"
  />
  <charge-virtual-key-modal
      v-if="isAdmin"
      :ft-client-id="ftClientId"
      :user.sync="userChargeModal"
      @close="reloadDelayed"
  />
</v-container>
</template>

<script>
import RevertTopUpVirtualKeyModal from "@/components/modals/commons/RevertTopUpVirtualKeyModal.vue";
import foodytekUtils from "@/mixins/foodytekUtils";
import TopUpVirtualKeyModal from "@/components/modals/commons/TopUpVirtualKeyModal.vue";
import ChargeVirtualKeyModal from "@/components/modals/administration/ChargeVirtualKeyModal.vue";
import stripe from "@/mixins/stripe";
import {mapGetters} from "vuex";

export default {
  name: 'UsersTransactions',
  components: { ChargeVirtualKeyModal, TopUpVirtualKeyModal, RevertTopUpVirtualKeyModal },
  mixins: [foodytekUtils, stripe],
  data () {
    return {
      userDetails: {},
      vk: {
        balance: null,
        lastTimeUsed: null
      },
      transactions: [],

      testCardTopUp: {
        show: false, // change this to show Stripe payment element -> not yet fully integrated and working
        amount: null,
        showPaymentElement: false
      },

      userTopUpModal: null,
      userChargeModal: null,
      userReverseTopUpModal: null,
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isClient', 'isGolfClient', 'isAdminLoggedAsClient', 'isAdminRole']),
    ftClientId () {
      return this.$route.params.ftClientId
    },
    userId () {
      return this.$route.params.userId
    },
    headers () {
      return [
        {
          text: 'Id',
          value: 'id',
          filterable: false,
          hide: !this.isAdmin
        },
        {
          text: this.$t('Date and time'),
          value: 'datetime',
          filterable: false
        },
        {
          text: this.$t('Device'),
          value: 'serialNumber',
          filterable: false
        },
        {
          text: this.$t('Amount (non discounted price in brackets)'),
          value: 'amount',
          filterable: false
        },
        {
          text: this.$t('Refunded'),
          value: 'refunded'
        },
        {
          text: this.$t('Type'),
          value: 'type'
        },
        {
          text: this.$t('Actions'),
          value: 'actions'
        }
      ].filter(h => !h.hide)
    }
  },
  mounted () {
    this.reload()

    if (this.$env.isLocalhost) {
      this.loadStripe()
    }
  },
  methods: {
    reloadDelayed () {
      setTimeout(this.reload, 700)
    },
    reload () {
      this.getUserDetails()
      this.getTransactions()
    },
    async getTransactions () {
      try {
        const response = await this.$http.payment(`virtual-keys/admin/${this.ftClientId}/${this.userId}/transactions`)
        this.transactions = response.data
      } catch (e) {
      }
    },
    async getUserDetails () {
      try {
        const response = await this.$http.payment(`virtual-keys/admin/${this.ftClientId}/${this.userId}`)
        this.userDetails = response.data.user
        this.vk.balance = response.data.balance
        this.vk.lastTimeUsed = response.data.lastTimeUsed
      } catch (e) {
      }
    },
    async refundTransaction (item) {
      item.disabled = true
      const res = await this.$dialog.confirm({
        title: this.$t('Refund transaction'),
        text: this.$t('Do you confirm that you want to refund this transaction? (Amount: {0})', [this.formatMoney(item.amount)]),
      })
      if (!res) return

      await this.$http.payment.patch(
          `virtual-keys/admin/${this.ftClientId}/${this.userId}/transactions/${item.id}/refund`
      )
      setTimeout(this.getTransactions, 600)
      await this.getTransactions()
    },
    topUpVK () {
      this.userTopUpModal = this.userDetails
    },
    reverseTopUp() {
      this.userReverseTopUpModal = this.userDetails
    },
    getLast4Digits (text) {
      return '...' + text.slice(-4)
    },
    formatMoneyWithSign(value, item) {
      const negative = item.type === 'PURCHASE_VK' || item.type.startsWith('REVERSE_TOP_UP_')
      return this.formatMoney(negative ? -value : value)
    },
    performCardTopUp () {
      this.testCardTopUp.showPaymentElement = true
      // this.loadStripe()
    },
  }
}
</script>
