<template>
  <div v-if="users">
    <div class="mx-8 my-6" style="font-size: 1.2rem;">
      {{ $t('Total users balance:') }}
      <span class="font-weight-bold">{{ formatMoney(totalBalances) }}</span>
    </div>

    <v-card outlined>
      <v-data-table
          v-if="ftClientId && users"
          :headers="headers"
          :items="users"
          :search="query.q"
          :items-per-page.sync="query.itemsPerPage"
          :page.sync="query.page"
          item-key="userId"
          sort-by="name"
          class="text-center"
      >
        <template v-slot:top>
          <div class="d-flex align-center px-6 mt-5">
            <v-text-field
                class="mr-6"
                v-model="query.q"
                :label="$t('Search users in the table (first name, last name, email...)')"
            ></v-text-field>
            <email-create-v-k-modal :ft-client-id="ftClientId" @close="reload"/>
          </div>
        </template>

        <template v-slot:item.name="{ item, value }">
          <v-chip
              outlined
              @click="openTransactions(item)"
          >
            {{ value }}
          </v-chip>
        </template>
        <template v-slot:item.balance="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.created="{ value }">
          {{ formatDateNoSecs(value) }}
        </template>
        <template v-slot:item.lastTimeUsed="{ value }">
          {{ formatDateNoSecs(value) }}
        </template>

        <template v-slot:item.groups="{ value, item }">
          <div>
            <v-chip
                v-for="group in value"
                :key="group.id"
                outlined
                small
                :to="`/groups-management/${group.id}`"
                class="mr-2"
                :disabled="group.loading"
                @contextmenu.prevent="showContextMenu($event, group, item.user)"
            >
              {{ group.name }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="d-flex">
            <v-tooltip bottom v-if="isClient || isAdminRole">
              <template v-slot:activator="{on, attrs}">
                <v-btn small icon v-bind="attrs" v-on="on"
                       color="primary"
                       @click="topUpVK(item)"
                >
                  <v-icon>
                    fa-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Top up user') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isClient || isAdminRole">
              <template v-slot:activator="{on, attrs}">
                <v-btn small icon v-bind="attrs" v-on="on"
                       class="mx-1" color="primary"
                       @click="userReverseTopUpModal = item.user"
                >
                  <v-icon>
                    fa-minus
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Correct top up (remove credit)') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isAdmin">
              <template v-slot:activator="{on, attrs}">
                <v-btn small icon v-bind="attrs" v-on="on"
                       class="mx-1" color="primary"
                       @click="userChargeModal = item"
                >
                  <v-icon>
                    fa-cart-shopping
                  </v-icon>
                </v-btn>
              </template>
              <span>Create purchase (admin only)</span>
            </v-tooltip>

            <v-tooltip bottom v-if="isClient && allowDayEntranceModal.visible">
              <template v-slot:activator="{on, attrs}">
                <v-btn small icon v-bind="attrs" v-on="on"
                       class="mx-2" color="primary"
                       @click="allowDayEntranceModal.user = item"
                >
                  <v-icon>
                    fa-golf-ball-tee
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Allow day entrance') }}</span>
            </v-tooltip>

            <v-tooltip bottom v-if="isClient">
              <template v-slot:activator="{on, attrs}">
                <v-btn x-small icon color="primary"
                       v-bind="attrs"
                       v-on="on"
                       class="mx-1"
                       @click="addUserToGroupModal = item"
                >
                  <v-icon>
                    fa-user-group
                  </v-icon>
                </v-btn>
              </template>
              <span>Aggiungi utente ad un gruppo</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>

      <!-- Context Menu for right-click -->
      <v-menu
          v-model="groupContextMenu.visible"
          :position-x="groupContextMenu.x"
          :position-y="groupContextMenu.y"
          absolute
      >
        <v-list>
          <v-list-item @click="removeUserFromGroup">
            <v-list-item-title>Rimuovi utente da questo gruppo</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <top-up-virtual-key-modal
          :ft-client-id="ftClientId"
          :user.sync="userTopUpModal"
          @close="reload"
      />
      <charge-virtual-key-modal
          v-if="isAdmin"
          :ft-client-id="ftClientId"
          :user.sync="userChargeModal"
          @close="reload"
      />
      <revert-top-up-virtual-key-modal
          :ft-client-id="ftClientId"
          :user.sync="userReverseTopUpModal"
          @close="reload"
      />
      <add-user-to-group-modal
          v-if="isClient"
          :user.sync="addUserToGroupModal"
          :ft-client-id="ftClientId"
          @close="reload"
      />
      <allow-day-entrance-to-user-modal
          v-if="isClient"
          :user.sync="allowDayEntranceModal.user"
          :visible.sync="allowDayEntranceModal.visible"
          :ft-client-id="ftClientId"
          @close="reload"
      />
    </v-card>
  </div>
</template>

<script>

import RevertTopUpVirtualKeyModal from '@/components/modals/commons/RevertTopUpVirtualKeyModal.vue'
import queryAutoUpdate from "@/mixins/queryAutoUpdate";
import TopUpVirtualKeyModal from "@/components/modals/commons/TopUpVirtualKeyModal.vue";
import EmailCreateVKModal from "@/components/modals/business/EmailCreateVKModal.vue";
import AddUserToGroupModal from "@/components/modals/business/AddUserToGroupModal.vue";
import ChargeVirtualKeyModal from "@/components/modals/administration/ChargeVirtualKeyModal.vue";
import {mapGetters} from "vuex";
import AllowDayEntranceToUserModal from "@/components/modals/business/AllowDayEntranceToUserModal.vue";

export default {
  name: 'UserVirtualKeysTable',
  components: {
    AllowDayEntranceToUserModal,
    ChargeVirtualKeyModal,
    AddUserToGroupModal,
    EmailCreateVKModal,
    TopUpVirtualKeyModal,
    RevertTopUpVirtualKeyModal
  },
  mixins: [ queryAutoUpdate ],
  props: {
    ftClientId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      query: {
        q: '',
        page: 1,
        itemsPerPage: 10,
      },
      users: null,
      userTopUpModal: null,
      userChargeModal: null,
      userReverseTopUpModal: null,
      addUserToGroupModal: null,
      allowDayEntranceModal: {
        user: null,
        visible: false,
      },
      tableFilter: this.$route.query.query ?? '',

      groupContextMenu: {
        visible: false,
        x: 0,
        y: 0, // Coordinates for the context menu
        group: null, // Stores the group object when right-clicked
        user: null, // Stores the group object when right-clicked
      },
    }
  },
  computed: {
    ...mapGetters([ 'isAdmin', 'isGolfClient', 'isClient', 'isAdminRole' ]),
    totalBalances () {
      return this.users.reduce((acc, user) => acc + user.balance, 0)
    },
    headers () {
      return [
        {
          text: 'User id',
          align: 'start',
          value: 'user.id',
          hide: !this.isAdmin
        },
        {
          text: this.$t('Name'),
          align: 'start',
          value: 'name'
        },
        {
          text: 'Email',
          align: 'start',
          value: 'user.email'
        },
        {
          text: this.$t('Balance'),
          align: 'start',
          value: 'balance',
          filterable: false
        },
        {
          text: this.$t('Registered at'),
          align: 'start',
          value: 'created',
          filterable: false
        },
        {
          text: this.$t('Last time used'),
          align: 'start',
          value: 'lastTimeUsed',
          filterable: false
        },
        {
          text: this.$t('Groups'),
          align: 'start',
          value: 'groups'
        },
        {
          text: this.$t('Actions'),
          align: 'start',
          value: 'actions',
          sortable: false,
          filterable: false
        }
      ].filter(header => !header.hide)
    }
  },
  watch: {
    ftClientId (value) {
      this.ftClientSelected(value)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    reload () {
      setTimeout(this.init, 1000)
    },
    init () {
      this.getUsersWallet(this.ftClientId)
    },
    async getUsersWallet (ftClientId) {
      try {
        const response = await this.$http.payment('virtual-keys/admin/' + ftClientId)
        this.users = response.data.map(u => {
          u.name = u.user.surname + ' ' + u.user.name
          return u
        })
      } catch (e) {
      }
    },
    ftClientSelected (ftClientId) {
      if (!this.$store.getters.isAdmin) return
      this.$router.push('/users-wallet/' + (ftClientId ?? ''))
      if (ftClientId) this.getUsersWallet(ftClientId)
      else this.users = []
    },
    openTransactions (row) {
      this.$router.push(`/users-wallet/${this.ftClientId}/transactions/${row.user.id}`)
    },
    topUpVK (row) {
      this.userTopUpModal = row.user
    },
    allowUserDayEntrance (row) {
      this.userTopUpModal = row.user
    },

    showContextMenu (event, group, user) {
      // Show the menu at the mouse's right-click position
      this.groupContextMenu.x = event.clientX;
      this.groupContextMenu.y = event.clientY;
      this.groupContextMenu.visible = true;
      this.groupContextMenu.group = group; // Store the right-clicked group
      this.groupContextMenu.user = user; // Store the right-clicked group
    },
    async removeUserFromGroup () {
      this.groupContextMenu.visible = false; // Hide the menu after click
      this.groupContextMenu.group.loading = true // disable chip to prevent double-click
      const { user, group } = this.groupContextMenu
      try {
        const res = await this.$dialog.confirm({
          title: 'Rimuovi utente',
          text: `Sei sicuro di voler rimuovere l'utente <b>${user.email}</b> dal gruppo <b>${group.name}</b>?`,
        })
        if (!res) return
        await this.$http.auth.delete('/user-groups/' + group.id + '/users/' + user.id)
        this.reload()
      } catch (e) {
      }
    },
  }
}
</script>

<style scoped>

</style>
