var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"devices-transaction"}},[_c('h1',[_vm._v(_vm._s(_vm.$t('Devices Transactions')))]),_c('ft-from-to-select',{attrs:{"fetch-data":_vm.getDevicesTransactions}}),_c('v-card',{staticClass:"my-8",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items":_vm.devices,"headers":_vm.headers,"item-key":"item.id"},scopedSlots:_vm._u([{key:"item.purchases",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.legacy",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.cashTopUps",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.lastLegacyUpdate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value, _vm.$t('No update in selected period')))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }