export default {
  methods: {
    downloadFile(data, filename) {
      // Create a link element
      const link = document.createElement('a')
      link.href = data
      link.target = '_blank'
      link.download = filename // Filename for the downloaded file

      // Append the link to the body and trigger the download
      document.body.appendChild(link)
      link.click()

      // Remove the link after download
      document.body.removeChild(link)
    },
    waitAsync (millis) {
      return new Promise((success) => {
        setTimeout(success, millis)
      })
    }
  }
}
